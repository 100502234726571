*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  color: $text-color;
  background-color: $body-bg;
}

a {
  color: $link-color;

  &:hover,
  &:focus {
    color: $link-color-hover;
  }
}
