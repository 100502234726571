.#{$prefix}-button {
  display: inline-block;
  margin: 0;
  // padding: 0 12px;
  padding: 8px;
  border: 0 none;
  border-radius: 3px;
  // border: 1px solid transparent;
  font-size: 14px;
  // line-height: 36px;
  line-height: 20px;
  user-select: none;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  // white-space: nowrap;
  outline: 0;
  background-color: $transparent;
  color: $N400;

  // 버튼 내부 텍스트
  .#{$prefix}-button__text {
    display: inline-block;
    padding: 0 4px;
  }

  // 공통 적용
  &:not([disabled]) {
    cursor: pointer;
  }

  // 공통 적용
  &:focus:not([disabled]),
  &.#{$prefix}-button--focus:not([disabled]) {
    box-shadow: 0 0 0 2px rgba($B400, 0.4);
  }

  &:hover {
    color: $N400;
  }

  // 사이즈
  &--large {
    padding: 12px;
    font-size: 16px;
    line-height: 24px;
  }

  &--small {
    padding: 4px;
    font-size: 14px;
    line-height: 20px;
  }

  // block 일 때
  &--block {
    display: block;
    width: 100%;

    & + .#{$prefix}-button--block {
      margin-top: 4px;
    }
  }

  // disabled
  &--link,
  &--subtle-link {
    &:hover:not([disabled]),
    &.#{$prefix}-button--hover:not([disabled]),
    &:focus:not([disabled]),
    &.#{$prefix}-button--focus:not([disabled]) {
      text-decoration: underline;
      border: 0 none;
    }
  }

  // a 태그가 disabled 적용 될 때
  &.#{$prefix}-button--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  // 이미지(아이콘) 버튼
  &.#{$prefix}-button--icon {
    height: 36px;
    line-height: 20px;
    padding: 8px;
    border-radius: 3px;
    // background-color: $transparent;

    &:hover {
      background-color: $N20;
    }

    .#{$prefix}-button__icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      vertical-align: top;

      i {
        font-size: 20px;
        vertical-align: top;
      }
    }

    // 배경색 있는 버튼일 때 아이콘 이미지를 흰색으로 적용 (primary, warning, danger, selected)
    &.#{$prefix}-button--primary,
    &.#{$prefix}-button--warning,
    &.#{$prefix}-button--danger,
    &.#{$prefix}-button--selected {
      .#{$prefix}-button__icon {
        color: $N0;
      }
    }
  }
}

// button-Colors
@each $type, $value in $button-colors {
  .#{$prefix}-button {
    &--#{$type} {
      outline: 0;
      background-color: button-color($type, 'defaultBackgroundColor');
      color: button-color($type, 'defaultColor');
      // @if ($type == 'link') or ($type == 'subtle-link') {
      //   text-decoration: underline;
      //   border: 0 none;
      // }

      &:hover:not([disabled]),
      &.#{$prefix}-button--hover:not([disabled]) {
        @if ($type == 'link') or ($type == 'subtle-link') {
          .#{$prefix}-button__text {
            text-decoration: underline;
            border: 0 none;
          }
        }

        @else {
          .#{$prefix}-button__text {
            text-decoration: none;
          }
        }

        // text-decoration: none;
        background-color: button-color($type, 'hoverBackgroundColor');
        color: button-color($type, 'hoverColor');
      }

      &:focus:not([disabled]),
      &.#{$prefix}-button--focus:not([disabled]) {
        text-decoration: none;
        background-color: button-color($type, 'focusBackgroundColor');
        color: button-color($type, 'focusColor');
      }

      &:disabled,
      &.#{$prefix}-button--disabled {
        background-color: button-color($type, 'disabledBackgroundColor');
        color: button-color($type, 'disabledColor');
      }

      &:active:not([disabled]),
      &.#{$prefix}-button--active:not([disabled]) {
        background-color: button-color($type, 'activeBackgroundColor');
        color: button-color($type, 'activeColor');
      }
    }

    // 선택한 상태일 때 각 버튼에서 class="xu-button--selected" 를 추가
    &--#{$type}.#{$prefix}-button--selected {
      background-color: button-color($type, 'selectedBackgroundColor') !important;
      color: button-color($type, 'selectedColor') !important;
    }
  }
}

// 버튼그룹
.#{$prefix}-button-group {
  display: inline-block;
}

// 버튼그룹 (라디오)
.#{$prefix}-button-group--radio {
  display: inline-block;

  .#{$prefix}-button {
    float: left;
    margin: 0 1px;
    border-radius: 0;

    &.#{$prefix}-button--selected {
      background-color: #345bd9 !important;
      color: #fff !important;
    }

    &:first-of-type {
      margin-left: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-of-type {
      margin-right: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}
