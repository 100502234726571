// 드롭다운 스타일
.#{$prefix}-dropdown,
.#{$prefix}-dropup,
.#{$prefix}-dropleft,
.#{$prefix}-dropright {
  position: relative;
  display: inline-block;
}

.#{$prefix}-dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 160px;
  margin: 4px 0 0;
  padding: 8px 0;
  border: 1px solid #cecece;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px -1px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  list-style: none;
  text-align: left;
  z-index: 1000;

  // 위로 노출
  .#{$prefix}-dropup & {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 5px;
  }

  // 왼쪽 노출
  .#{$prefix}-dropleft & {
    top: 0;
    left: auto;
    right: 100%;
    margin-top: 0;
    margin-right: 4px;
  }

  // 오른쪽 노출
  .#{$prefix}-dropright & {
    top: 0;
    left: 100%;
    margin-top: 0;
    margin-left: 4px;
  }

  &.#{$prefix}-dropdown-menu--show {
    display: block;
  }

  &.#{$prefix}-dropdown-menu--right {
    left: auto;
    right: 0;
  }

  .#{$prefix}-dropdown-menu__item {
    margin: 0;
    padding: 0;
    list-style: none;

    &.on {
      a,
      button {
        background-color: #deebff;
      }
    }

    a,
    button {
      display: block;
      width: 100%;
      padding: 4px 10px;
      border: 0 none;
      font-size: 14px;
      line-height: 20px;
      background-color: transparent;
      text-align: left;
      color: #172b4d;
      text-decoration: none;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: #f4f5f7;
      }

      &:active {
        background-color: transparent;
        color: #345bd9;
      }
    }
  }

  .#{$prefix}-dropdown-menu__divider {
    width: 100%;
    height: 1px;
    margin: 13px 0;
    background-color: #e6e6e6;
  }
}
