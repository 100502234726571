// prefix
$prefix: 'xu' !default;

// Neutrals
// 회색조를 대체하는 색상
// Dark neutrals
$N900: #091e42 !default;
$N800: #172b4d !default;
$N700: #253858 !default;
$N600: #344563 !default;
$N500: #42526e !default;
// Mid-neutrals
$N400: #505f79 !default;
$N300: #5e6c84 !default;
$N200: #6b778c !default;
$N100: #7a869a !default;
$N90: #8993a4 !default;
$N80: #97a0af !default;
$N70: #a5adba !default;
$N60: #b3bac5 !default;
// Light neutrals
$N50: #c1c7d0 !default;
$N40: #dfe1e6 !default;
$N30: #ebecf0 !default;
$N20: #f4f5f7 !default;
$N10: #fafbfc !default;
$N0: #fff !default;

// Blues
$B900: #131b40 !default;
$B800: #1b2452 !default;
$B700: #202e78 !default;
$B600: #0747a6 !default;
$B500: #345bd9 !default;
$B400: #2684ff !default;
$B300: #b3d4ff !default;
$B200: #deebff !default;
$B100: #ebf5fa !default;

// Extended palette
$R900: #330101 !default;
$R700: #bf0711 !default;
$R500: #de3618 !default;
$R400: #ff5630 !default;
$R300: #fead9a !default;
$R100: #fbeae5 !default;

// Oranges
$O900: #4a1504 !default;
$O700: #c05717 !default;
$O500: #f49342 !default;
$O400: #ffab00 !default;
$O300: #ffc58b !default;
$O100: #fcebdb !default;

// Yellows
$Y900: #573b00 !default;
$Y700: #8a6116 !default;
$Y500: #eec200 !default;
$Y300: #ffea8a !default;
$Y100: #fcf1cd !default;

// Greens
$G900: #173630 !default;
$G700: #018055 !default;
$G500: #36b375 !default;
$G300: #bff2cc !default;
$G100: #e3fce5 !default;

// Teals : 청록색
$T900: #013c3f !default;
$T700: #008da6 !default;
$T500: #00b8d9 !default;
$T300: #b5e7ef !default;
$T100: #e0f5f5 !default;

// Purples
$P900: #230051 !default;
$P700: #50248f !default;
$P500: #9c6ade !default;
$P300: #e3d0ff !default;
$P100: #f6f0fd !default;

$N900-95: rgba(9, 30, 66, 0.95) !default;
$N900-89: rgba(9, 30, 66, 0.89) !default;
$N900-82: rgba(9, 30, 66, 0.82) !default;
$N900-77: rgba(9, 30, 66, 0.77) !default;
$N900-71: rgba(9, 30, 66, 0.71) !default;
$N900-65: rgba(9, 30, 66, 0.65) !default;
$N900-60: rgba(9, 30, 66, 0.6) !default;
$N900-54: rgba(9, 30, 66, 0.54) !default;
$N900-48: rgba(9, 30, 66, 0.48) !default;
$N900-42: rgba(9, 30, 66, 0.42) !default;
$N900-36: rgba(9, 30, 66, 0.36) !default;
$N900-31: rgba(9, 30, 66, 0.31) !default;
$N900-25: rgba(9, 30, 66, 0.25) !default;
$N900-13: rgba(9, 30, 66, 0.13) !default;
$N900-8: rgba(9, 30, 66, 0.08) !default;
$N900-4: rgba(9, 30, 66, 0.04) !default;
$N900-2: rgba(9, 30, 66, 0.02) !default;

$brand-primary: $B500 !default;
$brand-success: $G500 !default;
$brand-positive: $T500 !default;
$brand-warning: $O500 !default;
$brand-danger: $R500 !default;
$brand-fail: $P500 !default;
$brand-black: $N800 !default;

$body-bg: $N0 !default;
$text-color: $N800 !default;
$link-color: $B500 !default;
$link-color-hover: $B400 !default;
$font-size-base: 16px !default;

$transparent: transparent !default;

// 버튼 상태별 컬러 변수
$button-colors: (
  'default': (
    'defaultBackgroundColor': $N20,
    'defaultColor': $N400,
    'hoverBackgroundColor': $N30,
    'hoverColor': $N400,
    'disabledBackgroundColor': $N20,
    'disabledColor': $N70,
    'selectedBackgroundColor': $N700,
    'selectedColor': $N0,
    'activeBackgroundColor': $B200,
    'activeColor': $B500,
    'focusBackgroundColor': $N20,
    'focusColor': $N400,
    'focusBoxShadowColor': $B400
  ),
  'primary': (
    'defaultBackgroundColor': $B500,
    'defaultColor': $N0,
    'hoverBackgroundColor': $B400,
    'hoverColor': $N0,
    'disabledBackgroundColor': $N20,
    'disabledColor': $N70,
    'selectedBackgroundColor': $N700,
    'selectedColor': $N0,
    'activeBackgroundColor': $B600,
    'activeColor': $N0,
    'focusBackgroundColor': $B500,
    'focusColor': $N0,
    'focusBoxShadowColor': $B400
  ),
  'link': (
    'defaultBackgroundColor': $transparent,
    'defaultColor': $B500,
    'hoverBackgroundColor': $transparent,
    'hoverColor': $B400,
    'disabledBackgroundColor': $transparent,
    'disabledColor': $N70,
    'selectedBackgroundColor': $N700,
    'selectedColor': $N0,
    'activeBackgroundColor': $transparent,
    'activeColor': $B600,
    'focusBackgroundColor': $transparent,
    'focusColor': $B500,
    'focusBoxShadowColor': $B400
  ),
  'subtle': (
    'defaultBackgroundColor': $transparent,
    'defaultColor': $N600,
    'hoverBackgroundColor': $N30,
    'hoverColor': $N400,
    'disabledBackgroundColor': $transparent,
    'disabledColor': $N70,
    'selectedBackgroundColor': $N700,
    'selectedColor': $N0,
    'activeBackgroundColor': $B200,
    'activeColor': $B500,
    'focusBackgroundColor': $transparent,
    'focusColor': $N600,
    'focusBoxShadowColor': $B400
  ),
  'subtle-link': (
    'defaultBackgroundColor': $transparent,
    'defaultColor': $N200,
    'hoverBackgroundColor': $transparent,
    'hoverColor': $N80,
    'disabledBackgroundColor': $transparent,
    'disabledColor': $N70,
    'selectedBackgroundColor': $N700,
    'selectedColor': $N0,
    'activeBackgroundColor': $transparent,
    'activeColor': $N400,
    'focusBackgroundColor': $transparent,
    'focusColor': $N200,
    'focusBoxShadowColor': $B400
  ),
  'warning': (
    'defaultBackgroundColor': $O500,
    'defaultColor': $N0,
    'hoverBackgroundColor': $O400,
    'hoverColor': $N0,
    'disabledBackgroundColor': $N20,
    'disabledColor': $N70,
    'selectedBackgroundColor': $N700,
    'selectedColor': $N0,
    'activeBackgroundColor': $O700,
    'activeColor': $N0,
    'focusBackgroundColor': $O500,
    'focusColor': $N0,
    'focusBoxShadowColor': $B400
  ),
  'danger': (
    'defaultBackgroundColor': $R500,
    'defaultColor': $N0,
    'hoverBackgroundColor': $R400,
    'hoverColor': $N0,
    'disabledBackgroundColor': $N20,
    'disabledColor': $N70,
    'selectedBackgroundColor': $N700,
    'selectedColor': $N0,
    'activeBackgroundColor': $R700,
    'activeColor': $N0,
    'focusBackgroundColor': $R500,
    'focusColor': $N0,
    'focusBoxShadowColor': $B400
  )
);
